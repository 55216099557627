import { createAsyncThunk } from "@reduxjs/toolkit";
import { odiffResult } from "odiff";
import { getWorkerInstance } from "../../App";
import { store } from "../../app/store";
import {
  VersionedSettings,
  VersionedSettingss,
} from "../../model/versioned-settings.model";
import { getData, postData } from "../../network/request";
import { getVersions } from "../../utils/getVersions";
import sanitiseSettings from "../../utils/sanitiseSettings";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { setVersionOptions } from "./components/Filter/filterSlice";
import {
  addSetting,
  clearChanges,
  deleteSetting,
  incrementCurrentSaveNumber,
  resetCurrentSaveNumber,
  setNumberOfSettingsToSave,
  setRequestStatus,
  setUnsavedSettings,
  updateSettings,
} from "./addToAllSlice";

export const fetchConfig = createAsyncThunk("config/fetch", async () => {
  return await getData("/admin/config");
});

export const fetchGameEditions = createAsyncThunk(
  "game-editions/fetch",
  async () => {
    return await getData("/admin/game-editions");
  }
);

export const fetchSettings = createAsyncThunk(
  "settings/fetch",
  async (nothing: null, { dispatch }: any) => {
    dispatch(setRequestStatus("Requesting settings..."));
    const onDownloadProgress = (progressEvent: ProgressEvent) => {
      dispatch(
        setRequestStatus(
          `Downloading settings: ${(progressEvent.loaded / 10000000).toFixed(
            1
          )} MB`
        )
      );
    };

    const response = await getData(
      "/admin/versioned-settings",
      undefined,
      undefined,
      onDownloadProgress
    );

    const versionOptions = getVersions(response.versioned_settingss);

    dispatch(setVersionOptions(versionOptions));

    return response.versioned_settingss;
  }
);

export const saveSelectedSettings = createAsyncThunk(
  "settings/saveSelected",
  //@ts-ignore
  async (
    {
      selectedSettings,
      changes,
      selectedSettingsWithChanges,
    }: {
      selectedSettings: VersionedSettingss;
      changes?: odiffResult[];
      selectedSettingsWithChanges?: VersionedSettingss;
    },
    { dispatch }: any
  ) => {
    dispatch(setNumberOfSettingsToSave(selectedSettings.length));

    const filteredChanges = changes?.filter(
      (change: odiffResult) => change.path.length > 1
    );

    let selectedSettingsWithChangesModified = selectedSettingsWithChanges
      ? selectedSettingsWithChanges
      : filteredChanges
      ? await getWorkerInstance().applyChangesToSettings(
          selectedSettings,
          filteredChanges
        )
      : selectedSettings;

    const serverSettingsToUpdate: VersionedSettingss = [];

    const erroredSettings: VersionedSettingss = [];

    // Save settings
    await Promise.all(
      selectedSettingsWithChangesModified.map(
        async (setting: VersionedSettings, index: number) => {
          const sanitisedSetting = sanitiseSettings(setting);

          // Post data
          await postData(
            `admin/versioned-settings/${sanitisedSetting.object_id}/update`,
            sanitisedSetting
          )
            .then((res) => {
              serverSettingsToUpdate.push(res.versioned_settings);
              dispatch(incrementCurrentSaveNumber());
            })
            .catch((err) => {
              console.error(err);
              erroredSettings.push(sanitisedSetting);
              if (err.response?.status === 409) {
                // Cas error, settings on server is newer
                store.dispatch(
                  queueNotification({
                    message: `Someone has updated setting ${sanitisedSetting.object_id} since you loaded this page, you cannot save this setting. Refresh the page and try again`,
                    options: {
                      key: `cas_error_${sanitisedSetting.object_id}`,
                      variant: SnackbarVariant.WARNING,
                    },
                  })
                );
              } else {
                // Error saving settings
                store.dispatch(
                  queueNotification({
                    message: `Error saving setting ${sanitisedSetting.object_id}`,
                    options: {
                      key: `save_error_${sanitisedSetting.object_id}`,
                      variant: SnackbarVariant.ERROR,
                    },
                  })
                );
              }
            });
        }
      )
    );
    //Update settings with responses from server
    dispatch(updateSettings(serverSettingsToUpdate));

    dispatch(setUnsavedSettings(erroredSettings));

    // Clear changes
    dispatch(clearChanges());

    dispatch(resetCurrentSaveNumber());

    dispatch(setNumberOfSettingsToSave(undefined));
  }
);

export const addGameSettings = createAsyncThunk(
  "settings/add",
  async (setting: VersionedSettings, { dispatch }: any) => {
    try {
      const res = await postData(
        `admin/versioned-settings`,
        sanitiseSettings(setting)
      );
      dispatch(addSetting(res.versioned_settings));
      store.dispatch(
        queueNotification({
          message: `Game settings successfully added!`,
          options: {
            key: `game_settings_add`,
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );
    } catch (err) {
      console.error(err);

      // Error saving settings
      store.dispatch(
        queueNotification({
          message: `Error adding setting`,
          options: {
            key: `save_error`,
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      throw err;
    }
  }
);

export const deleteGameSettings = createAsyncThunk(
  "settings/delete",
  async (objectId: string, { dispatch }: any) => {
    postData(`/admin/versioned-settings/${objectId}/delete`).then(() => {
      // Remove settings from versioned setting
      dispatch(deleteSetting(objectId));

      store.dispatch(
        queueNotification({
          message: `Game settings successfully deleted`,
          options: {
            key: `game_settings_delete`,
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );
    });
  }
);

export const saveSettings = createAsyncThunk(
  "settings/save",
  async (
    {
      objectId,
      setting,
    }: { objectId: string; setting?: Partial<VersionedSettings> },
    { dispatch }: any
  ) => {
    let sanitisedSetting = setting;

    if (setting) {
      sanitisedSetting = sanitiseSettings(setting);
    }
    // Post data
    await postData(
      `admin/versioned-settings/${objectId}/update`,
      sanitisedSetting
    )
      .then((res) => {
        //Update settings with responses from server
        dispatch(updateSettings([res.versioned_settings]));
        store.dispatch(
          queueNotification({
            message: `Game settings saved successfully!`,
            options: {
              key: `game_settings_save`,
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 409) {
          // Cas error, settings on server is newer
          store.dispatch(
            queueNotification({
              message: `Someone has updated setting ${setting?.object_id} since you loaded this page, you cannot save this setting. Refresh the page and try again`,
              options: {
                key: `cas_error_${setting?.object_id}`,
                variant: SnackbarVariant.WARNING,
              },
            })
          );
        } else {
          // Error saving settings
          store.dispatch(
            queueNotification({
              message: `Error saving setting ${setting?.object_id}`,
              options: {
                key: `save_error_${setting?.object_id}`,
                variant: SnackbarVariant.ERROR,
              },
            })
          );
        }
      });
  }
);

export const combineSettings = createAsyncThunk(
  "settings/combine",
  async (selectedSettings: VersionedSettingss, { dispatch }: any) => {
    if (selectedSettings.length < 1) {
      return null;
    }

    // Clear changes
    dispatch(clearChanges());

    return await getWorkerInstance().generateCombinedSettings(selectedSettings);
  }
);
