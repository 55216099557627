import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FormContent } from "../../../components/CollectionEditor/FormContent";
import { FieldType, Form } from "../../../model/crud.model";
import { GameApps } from "../../../model/game-app.model";
import {
  ReactSelectArray,
  ReactSelectObject,
  VersionedSettings,
} from "../../../model/versioned-settings.model";
import { useGameApps } from "../../../network/useGameApps";
import { useGameEditions } from "../../../network/useGameEditions";
import { settingsSelector } from "../addToAllSlice";
import SelectedSettings from "./SelectedSettings";
import { useConfig } from "../../../network/useConfig";

export type SettingsSelectorValues = {
  game_edition_id?: string;
  country?: string;
  max_version?: string;
  game_edition_ids?: string[];
  country_codes?: string[];
  max_versions?: string[];
};

interface SettingsSelectorProps {
  setFormValue: (newValue: SettingsSelectorValues) => void;
  newValues: SettingsSelectorValues;
  filterVersions?: boolean;
  singleSelect?: boolean;
  showSelectedSettings?: boolean;
}

export const SettingsSelector = ({
  newValues,
  setFormValue,
  filterVersions,
  singleSelect,
  showSelectedSettings,
}: SettingsSelectorProps) => {
  // const countries = useSelector(countriesSelector);
  const settings = useSelector(settingsSelector);

  const { config } = useConfig();

  const { countries, country_groups } = config || {};

  const getGameVersions = (gameApps?: GameApps) => {
    return gameApps?.reduce((obj, gameApp) => {
      obj[gameApp.game_edition_id] = gameApp.versions.map((version) => {
        return { label: version.version_number, value: version.version_number };
      });

      return obj;
    }, {} as { [id: string]: ReactSelectArray });
  };

  const { gameApps } = useGameApps();
  const { gameEditions: gameEditionsRaw } = useGameEditions();

  const gameEditions = gameEditionsRaw?.map(
    (edition: { display_name: any; name: any }) => ({
      label: edition.display_name,
      value: edition.name,
    })
  );

  const versionsFromEndpoint = getGameVersions(gameApps);

  let concatenatedVersions: ReactSelectArray | undefined = [];

  if (versionsFromEndpoint) {
    gameEditions?.forEach((gameEdition: { value: string | number }) => {
      if (versionsFromEndpoint[gameEdition.value]) {
        versionsFromEndpoint[gameEdition.value].push({
          label: "Latest",
          value: "latest",
        });
        versionsFromEndpoint[gameEdition.value].reverse();
      }
    });

    Object.values(versionsFromEndpoint).forEach((value) => {
      concatenatedVersions = [...(concatenatedVersions ?? []), ...value];
    });

    if (filterVersions) {
      // Filter concatenated versions based on selected game editions
      concatenatedVersions = concatenatedVersions.filter(
        (version: ReactSelectObject) => {
          if (newValues.hasOwnProperty("game_edition_ids")) {
            // Check if in all ids version arrays
            return newValues?.game_edition_ids?.every(
              (game_edition_id: string) => {
                return versionsFromEndpoint[game_edition_id]
                  ?.map((version) => version.value)
                  .includes(version.value);
              }
            );
          } else {
            // Check if in all ids version arrays
            return versionsFromEndpoint[newValues?.game_edition_id ?? ""]
              ?.map((version) => version.value)
              .includes(version.value);
          }
        }
      );
    }
  }

  const formatCountries = (countries: any, country_groups: any) => {
    if (country_groups && countries) {
      const items = Object.entries(country_groups?.groups).map(
        ([key, value]) => {
          return {
            key: key,
            value: value,
          };
        }
      );

      const resp = items.map((i: any) => {
        return i.value.map((k: any) => ({
          group: i.key,
          value: k,
          label: countries?.find((j: any) => j.value === k).label,
        }));
      });

      return [
        { group: "Select All", value: "select-all", label: "Select All" },
        ...resp.flat(),
      ];
    }
  };

  const formattedCountries = useMemo(
    () => formatCountries(countries, country_groups),
    [countries, country_groups]
  );

  const model: Form = [
    {
      display_name: singleSelect ? "Game Edition" : "Game Editions",
      name: singleSelect ? "game_edition_id" : "game_edition_ids",
      type: singleSelect ? FieldType.SELECT : FieldType.MULTISELECT,
      options: gameEditions,
      required: true,
      selectOptions:
        !singleSelect && gameEditions
          ? [{ label: "Select All", value: "select-all" }, ...gameEditions]
          : undefined,
      limitTags: 10,
      id: "game_edition_autocomplete",
      label: "Game Editions",
      placeholder: "Game Editions",
      disabled: false,
      needGrouping: false,
      onChangeOverride: (value, obj) => {
        // On change, reset selected versions
        if (filterVersions) {
          if (obj.hasOwnProperty("max_versions")) {
            obj.max_versions = [];
          } else if (obj.hasOwnProperty("max_version")) {
            obj.max_versions = "";
          }
        }
      },
    },
    {
      display_name: singleSelect ? "Country" : "Countries",
      name: singleSelect ? "country" : "country_codes",
      type: singleSelect ? FieldType.SELECT : FieldType.MULTISELECT,
      options: countries,
      required: true,
      selectOptions: formattedCountries,
      limitTags: 10,
      id: "country_autocomplete",
      label: "Country List",
      placeholder: "countries",
      disabled: false,
      needGrouping: true,
    },
    {
      display_name: singleSelect ? "Max Version" : "Max Versions",
      name: singleSelect ? "max_version" : "max_versions",
      type: singleSelect ? FieldType.SELECT : FieldType.MULTISELECT,
      options: concatenatedVersions,
      required: true,
      selectOptions: [
        { label: "Select All", value: "select-all" },
        ...concatenatedVersions,
      ],
      limitTags: 10,
      id: "version_autocomplete",
      label: "Versions List",
      placeholder: "Versions List",
      disabled: false,
      needGrouping: false,
    },
  ];

  const settingIds = settings
    .filter((setting: VersionedSettings) => {
      if (singleSelect) {
        return (
          newValues.game_edition_id === setting.game_edition_id &&
          newValues.country === setting.country &&
          newValues.max_version === setting.max_version
        );
      } else {
        return (
          newValues.game_edition_ids?.includes(setting.game_edition_id) &&
          newValues.country_codes?.includes(setting.country) &&
          newValues.max_versions?.includes(setting.max_version)
        );
      }
    })
    .map((setting: VersionedSettings) => setting.object_id);

  return (
    <>
      <FormContent
        formValue={newValues}
        model={model}
        page="game_settings"
        setFormValue={setFormValue}
      />
      {showSelectedSettings && (
        <SelectedSettings selectedSettingsIds={settingIds} />
      )}
    </>
  );
};
